<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('common.filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4">
          <!-- countryCode -->
          <enum-select id="country-code-filter" v-model="countryCode" :label="$t('common.country')" text="text" enum-class="CountryCode" />
        </b-col>
        <b-col cols="12" md="4">
          <!-- organizationTypology -->
          <enum-select id="main-typology-filter" v-model="organizationTypology" :label="$t('organization.main_typology')" enum-class="OrganizationTypology" />
        </b-col>
        <b-col cols="12" md="4">
          <!-- organizationStatus -->
          <enum-select id="status-filter" v-model="organizationStatus" :label="$t('organization.status')" enum-class="OrganizationStatus" />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex align-items-center justify-content-end">
          <!-- :disabled="filtersOptionsLoading || organizationTypologyLoading || countryCodeLoading" -->
          <b-button
            id="action-reset-filter"
            :disabled="!organizationTypology && !countryCode"
            size="sm"
            variant="outline-secondary"
            class="mr-1"
            @click="clearFilters()"
          >
            {{ $t('action.clear_all') }}
          </b-button>
          <b-button id="action-filter" v-ripple.400 :disabled="!filtersChanged" size="sm" @click="fetchOrganizations(true), (filtersChanged = false)">
            {{ $t('action.filter') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import UIHelper from '@/helpers/ui'

export default {
  name: 'OrganizationFilters',

  components: {},

  mixins: [UIHelper],

  data() {
    return { filtersChanged: false }
  },

  computed: {
    ...mapState('organization', ['filtersOptions', 'filter', 'filtersOptionsLoading']),
    ...mapState('appConfig', ['lang']),

    ...mapFields('organization', ['filter.organizationTypology', 'filter.countryCode', 'filter.organizationStatus']),
  },

  watch: {
    filter: {
      handler() {
        this.filtersChanged = true
      },
      deep: true,
    },
  },

  // organization light
  methods: {
    ...mapActions('organization', ['fetchOrganizations', 'clearFilters']),
  },
}
</script>

<style lang="scss" scoped></style>
