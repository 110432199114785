var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('OrganizationFilters',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}]}),_c('b-card',{attrs:{"no-body":""}},[_c('app-data-table',{ref:"refOrganizationsListTable",attrs:{"table-name":"organization-list","fields":_vm.organizationsFields,"items":_vm.organizations,"page":_vm.page,"per-page":_vm.numberOfItemsPerPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.organizationsLoading,"manual-pagination":"","top-table":{
        showSearch: true,
        showFilter: true,
        searchFilterOptions: _vm.searchFilterOptions,
        searchText: _vm.searchText,
      },"actions-table":{ showEdit: _vm.$can('OPERATOR_ORGANIZATION_EDIT'), showDelete: _vm.$can('OPERATOR_ORGANIZATION_DELETE') },"bottom-table":{ totalCount: _vm.pagination.totalItems }},on:{"table-select-per-page":function($event){;(_vm.numberOfItemsPerPage = $event), _vm.fetchOrganizations(true)},"table-search-text":function($event){;(_vm.searchText = $event), _vm.fetchOrganizations(true)},"table-search-filter":function($event){;(_vm.searchFilterOptions = $event), _vm.fetchOrganizations(true)},"table-filter":function($event){_vm.showFilters = $event},"pagination":function($event){;(_vm.page = $event), _vm.fetchOrganizations(true)},"sort-changed":_vm.updateSort,"edit":function($event){return _vm.$router.push({
          name: 'editOrganization',
          params: {
            id: $event.item.id,
            tab: 'main-information',
          },
        })},"delete":function($event){return _vm.confirmDeleteAlert($event.item)}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(id)",fn:function(ref){
        var item = ref.item;
return [_c('b-link',{staticClass:"font-weight-bold",attrs:{"disabled":!_vm.$can('OPERATOR_ORGANIZATION_EDIT'),"to":{
            name: 'editOrganization',
            params: {
              id: item.id,
              tab: 'main-information',
            },
          }}},[_vm._v(" #"+_vm._s(item.id)+" ")])]}},{key:"cell(legalName)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(item.legalName))])])]}},{key:"cell(mainTypology)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(_vm._f("enumTranslate")(item.mainTypology,'organization_typology')))])])]}},{key:"cell(usersNumber)",fn:function(ref){
          var item = ref.item;
return [_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(item.usersNumber)+" ")])]}},{key:"cell(servicesNumber)",fn:function(ref){
          var item = ref.item;
return [_c('b-badge',{attrs:{"pill":"","variant":"primary"}},[_vm._v(" "+_vm._s(item.servicesNumber)+" ")])]}},{key:"cell(status)",fn:function(ref){
          var item = ref.item;
return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.resolveClassStatusOrganization(item.status)))}},[_vm._v(" "+_vm._s(_vm.$t(_vm.resolveTextStatusOrganization(item.status)))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }